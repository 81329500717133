import React from 'react';

import Head from '../../components/Head';
import StandardPage from '../../components/StandardPage';
import LearnMore from '../../components/LearnMore';

import '../../style/_common.scss';

const EssaysPage = () => {
  return (
    <StandardPage>
      <Head
        title="Essays"
        description="A collection of essays describing the direct primary model and how it compares to the current healthcare system."
        url="/essays/"
        image="/img/banner.png"
      />

      <div className="col ac jc wrap" style={{ backgroundColor: 'white', padding: '100px 0px' }}>
        <div className="row as jc wide">
          <div className="wide readable col js ac blog" style={{ paddingTop: '150px' }}>
            <h1 className="title" style={{ marginBottom: '0px' }}>
              Essays
            </h1>
            <p className="tac">Deep dives into healthcare and DPC</p>
            <br />
            <LearnMore faq market crisis />
          </div>
        </div>
      </div>
    </StandardPage>
  );
};

export default EssaysPage;
